const STORAGE_COLUMNS_NAME = 'invoices-columns';

const COLUMN_OPTIONS = [
    {
        value: 'from',
        label: 'From',
    },
    {
        value: 'to',
        label: 'To',
    },
    {
        value: 'payDate',
        label: 'Pay Date',
    },
    {
        value: 'amount',
        label: 'Amount',
    },
    {
        value: 'currency',
        label: 'Currency',
    },
    {
        value: 'paymentMethod',
        label: 'Payment Method',
    },
    {
        value: 'expiredAt',
        label: 'Expired Date',
    },
    {
        value: 'gateway',
        label: 'Payment Gateway',
    },
    {
        value: 'frequency',
        label: 'Frequency',
    },
];

export {
    COLUMN_OPTIONS,
    STORAGE_COLUMNS_NAME,
};
